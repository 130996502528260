import Vue from "vue";

import App from "./App.vue";
import router from "./router";
import i18n from "./lang";
import AuthSvc from "./service/auth.js";
import UserSvc from "./service/user.js";
import * as serviceWorker from "register-service-worker";

if (window.location.host.includes(".zakchat.shamaazi.io")) {
    const meta = document.createElement("meta");
    meta.name = "robots";
    meta.content = "noindex";
    document.querySelector("head")?.appendChild(meta);
}

const getGTMKey = () => {
    const testKey = "GTM-KCQDJMS";
    const prodKey = "GTM-KW36SRC";

    const match = document.location.hostname.match(/(?:www\.)?(?:([a-z]+)\.)?zakchat.com/);
    if (match && match[1] === undefined) {
        // on zakchat.com and there's no subdomain
        return prodKey;
    }

    return testKey;
};

(function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
})(window, document, "script", "dataLayer", getGTMKey());

serviceWorker.unregister();

AuthSvc.setInterceptors();
UserSvc.detectLocale();
export const stateHandler = new Vue();

new Vue({
    router,
    i18n,
    render: (h) => h(App),
}).$mount("#app");

const SocialSharing = require("vue-social-sharing");

Vue.use(SocialSharing);
