import Vue from "vue";
import VueI18n from "vue-i18n";

import UserSvc from "@/service/user.js";
import terms from "./terms";

const EN_CA = "en-CA";
const EN_US = "en-US";
const EN_GB = "en-GB";
const EN_ZA = "en-ZA";
const EN_AU = "en-AU";
const localeList = [EN_CA, EN_US, EN_GB, EN_ZA, EN_AU];

function getMessageConfig(locale) {
    return {
        terms: terms[locale],
    };
}

function getNumberFormatsConfig(currency) {
    return {
        currency: {
            style: "currency",
            currency,
        },
        currencyNoCents: {
            style: "currency",
            currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        },
    };
}

// potentially from Geolocation (google maps or other services)
const selectedLocale = localeList.find((l) => UserSvc.getDetectedLocale() === l) || EN_GB;

Vue.use(VueI18n);

const instanceVueI18n = new VueI18n({
    locale: selectedLocale,
    fallbackLocale: EN_GB,
    numberFormats: {
        [EN_CA]: getNumberFormatsConfig("CAD"),
        [EN_US]: getNumberFormatsConfig("USD"),
        [EN_GB]: getNumberFormatsConfig("GBP"),
        [EN_ZA]: getNumberFormatsConfig("ZAR"),
        [EN_AU]: getNumberFormatsConfig("AUD"),
    },
    messages: {
        [EN_CA]: getMessageConfig(EN_CA),
        [EN_US]: getMessageConfig(EN_US),
        [EN_GB]: getMessageConfig(EN_GB),
        [EN_ZA]: getMessageConfig(EN_ZA),
        [EN_AU]: getMessageConfig(EN_AU),
    },
});

function selectedCurrency() {
    return instanceVueI18n.getNumberFormat(instanceVueI18n.locale).currency.currency;
}

export { EN_CA, EN_US, EN_GB, EN_ZA, EN_AU, selectedLocale, selectedCurrency };

export default instanceVueI18n;
