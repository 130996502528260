import Vue from "vue";
import Router from "vue-router";

import MainLayout from "@/layouts/Main.vue";
import NotFound from "@/pages/NotFound";

import { isChatPublic } from "@/service/chatpublic.js";

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    linkActiveClass: "is-active",
    linkExactActiveClass: "is-exact",
    routes: [
        {
            path: "/",
            component: MainLayout,
            children: [
                {
                    path: "",
                    name: "Home",
                    component: () => import("@/pages/Home"),
                },
                {
                    path: "/unsubscribe",
                    name: "Unsubscribe",
                    component: () => import("@/pages/Unsubscribe"),
                },
                {
                    path: "/chat/:conversationId",
                    name: "ChatConversation",
                    component: () => import("@/pages/Chat"),
                    beforeEnter: (to, from, next) => {
                        if (!isChatPublic()) {
                            return next("/");
                        }
                        next();
                    },
                },
                {
                    path: "/chat",
                    name: "Chat",
                    component: () => import("@/pages/Chat"),
                    beforeEnter: (to, from, next) => {
                        if (!isChatPublic()) {
                            return next("/");
                        }
                        next();
                    },
                },
                {
                    path: "/share",
                    name: "Share",
                    component: () => import("@/pages/Share"),
                },
                {
                    path: "/zakat",
                    name: "Zakat",
                    component: () => import("@/pages/Zakat"),
                },
                {
                    path: "about",
                    name: "AboutUs",
                    component: () => import("@/pages/About"),
                },
                {
                    path: "legal",
                    name: "Legal",
                    component: () => import("@/pages/LegalAgreement"),
                },
                {
                    path: "privacy",
                    name: "Privacy",
                    component: () => import("@/pages/PrivacyPolicy"),
                },
                {
                    path: "faq",
                    name: "FAQ",
                    component: () => import("@/pages/FAQ"),
                },
                {
                    path: "/:charityId",
                    name: "WidgetConversation",
                    component: () => import("@/pages/Chat"),
                    beforeEnter: (to, from, next) => {
                        if (!isChatPublic()) {
                            return next("/");
                        }
                        next();
                    },
                },
            ],
        },
        {
            path: "*",
            component: MainLayout,
            children: [
                {
                    path: "",
                    name: "NotFound",
                    component: NotFound,
                },
            ],
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {
                x: 0,
                y: 0,
            };
        }
    },
});

router.afterEach((to, from) => {
    if (from.path !== to.path) {
        dataLayer.push({
            event: "pageview",
            page: to.path,
            name: to.name,
        });
    }
});

export default router;
