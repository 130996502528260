<template>
    <main>
        <router-view @authChange="handleAuthChange" />
    </main>
</template>

<script>
import UserSvc from "@/service/user.js";

export default {
    data() {
        return {
            isAuthenticated: UserSvc.isAuthenticated(),
            isSideNavOpen: false,
        };
    },

    methods: {
        handleAuthChange() {
            this.isAuthenticated = UserSvc.isAuthenticated();
        },

        handleToggleSideNav() {
            this.isSideNavOpen = !this.isSideNavOpen;
        },
    },

    watch: {
        $route() {
            this.isSideNavOpen = false;
        },
    },
};
</script>

<style lang="scss">
.zch-c-front-root {
    min-height: 100%;
    display: grid;
    grid-template-columns: 1fr 0;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
        "top-nav  side-nav"
        "content  .       "
        "footer   .       ";
    background-color: $color-white;
}

.zch-c-front-root_top-nav {
    grid-area: top-nav;
}

.zch-c-front-root_content {
    grid-area: content;
}

.zch-c-front-root_side-nav {
    grid-area: side-nav;
}

.zch-c-front-root_footer {
    grid-area: footer;
}
</style>
